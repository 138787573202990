/*  Button */
.mantine-button-root {
  font-weight: 400;
  font-size: var(--mantine-font-size-md);
  border-radius: var(--mantine-radius-sm);

  &[data-variant='default']:not([data-disabled]) {
    border: 1px solid rgba(0, 0, 0, 0.16);
    color: var(--mantine-primary-color-8);

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-1);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-2);
    }

    .mantine-button-loader > span::after {
      border-color: white var(--mantine-primary-color-8)
        var(--mantine-primary-color-8);
    }
  }

  &[data-variant='default-filled']:not([data-disabled]) {
    color: var(--mantine-primary-color-8);
    background-color: var(--mantine-color-gray-1);

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-1);
      color: var(--mantine-primary-color-7);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-2);
    }
  }

  &[data-variant='outline']:not([data-disabled]) {
    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-blue-0);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-2);
    }
  }

  &[data-variant='subtle']:not([data-disabled]) {
    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-1);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-gray-2);
    }
  }

  &[data-variant='filled']:not([data-disabled]) {
    border-color: var(--mantine-primary-color-9);

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-primary-color-7);
      border-color: var(--mantine-primary-color-8);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-primary-color-9);
      border-color: var(--mantine-primary-color-9);
    }
  }

  &[data-variant='white']:not([data-disabled]) {
    background: none;
    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: none;
      color: var(--mantine-primary-color-7);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: none;
      color: var(--mantine-primary-color-9);
    }
  }

  &[data-variant='danger']:not([data-disabled]) {
    background-color: var(--mantine-color-red-6);
    border-color: var(--mantine-color-red-7);

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-red-5);
      border-color: var(--mantine-color-red-6);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-red-7);
      border-color: var(--mantine-color-red-7);
    }
  }

  &[data-variant='danger-outline']:not([data-disabled]) {
    color: var(--mantine-color-red-6);
    border-color: var(--mantine-color-red-6);
    background: white;

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-red-0);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-red-1);
    }
  }

  &[data-variant='danger-outline'] {
    :global {
      .mantine-Loader-root {
        --loader-color: var(--mantine-color-red-6) !important;
      }
    }
  }

  &[data-variant='warning']:not([data-disabled]) {
    background-color: var(--mantine-color-orange-5);
    border-color: var(--mantine-color-orange-6);

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-orange-4);
      border-color: var(--mantine-color-orange-5);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-orange-5);
      border-color: var(--mantine-color-orange-6);
    }
  }

  &[data-variant='warning-outline']:not([data-disabled]) {
    color: var(--mantine-color-orange-6);
    border-color: var(--mantine-color-orange-6);
    background: white;

    &:hover:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-orange-0);
    }

    &:active:not([data-disabled]):not([data-loading]) {
      background-color: var(--mantine-color-orange-1);
    }
  }

  &[data-variant='warning-outline'] {
    :global {
      .mantine-Loader-root {
        --loader-color: var(--mantine-color-orange-6) !important;
      }
    }
  }

  &[data-size='xs'] {
    height: 26px;
    font-size: var(--mantine-font-size-xs);
  }

  &[data-size='sm'] {
    height: 40px;
  }

  &[data-size='md'] {
    height: 48px;
  }

  &[data-disabled] {
    border: 1px solid var(--mantine-color-gray-2);
  }
}
