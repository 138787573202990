.root {
  background-color: transparent;
}

.chevron {
  color: var(--mantine-primary-color-8);
  width: 20px;
  height: 20px;

  &[data-rotate='true'] {
    transform: rotate(0deg);
  }
  &:not([data-rotate]) {
    transform: rotate(-90deg);
  }

  svg {
    width: 20px !important;
    height: 20px !important;
  }
}

.content {
  padding: 0;
}

.size-sm {
  height: 40px;
}

.size-md {
  height: 48px;
}

.controlDefaultColor {
  &[data-active] {
    background-color: var(--mantine-color-blue-0);
  }
}
